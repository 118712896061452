@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
body {
  font-family: "Source Sans Pro", sans-serif !important;
}
.ant-input {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 1.3rem + 2px) !important;
  padding: 0.65rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #3f4254 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #e4e6ef !important;
  border-radius: 0.42rem !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.ant-input-affix-wrapper {
  border: none !important;
  padding: 0 !important;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
#basic_username::-webkit-input-placeholder::before {
  content: "Enter your username";
}
#basic_password {
  width: 180% !important;
  position: relative !important;
}
.ant-input-suffix {
  float: right !important;
  z-index: 1000 !important;
  position: absolute !important;
  right: 20px;
  top: 0;
  display: flex !important;
  height: 100%;
  align-items: center !important;
}
.ant-checkbox + span {
  font-size: 14px;
}
.ant-col-8 {
  max-width: 75% !important;
}
/* .ant-form-item-control {
  margin: 0% !important;
} */
.column {
  float: left;
  height: 100% !important;
  width: 50%;
  position: relative;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  position: absolute;
}
.ant-btn-primary {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
  display: inline-block !important;
  font-weight: 400 !important;
  color: #3f4254 !important;
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  /* background-color: transparent !important; */
  border: 1px solid transparent !important;
  /* padding: .65rem 1rem !important; */

  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.42rem !important;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out !important;
}

.ant-btn > span {
  /* background-color: #3699ff !important;
  border-color: #3699ff !important; */
}
.login_form {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login_form .main_block {
  width: 600px;
  margin: 0 auto;
}
.ant-layout-sider-children .logo {
  background-color: #48181b;
}
.banner {
  height: 100vh !important;
  width: 100%;
  object-fit: cover;
}
.login_form img {
  margin-bottom: 20px;
  width: 30% !important;
  padding: 0 !important;
  display: flex;
  margin: 0 auto !important;
  justify-content: center;
}
.login_form input {
  background-color: #f3f6f9 !important;
  border-color: #f3f6f9 !important;
  color: #3f4254 !important;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  height: 60px !important;
}
.login_form input:focus {
  background-color: #ebedf3 !important;
  border-color: #ebedf3 !important;
  color: #3f4254 !important;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}
.login_form button {
  background: #642226 !important;

  padding: 0 30px !important;
  border: 1px solid #642226 !important;
  border-color: #642226 !important;
  transition: all 0.5s ease;
  width: 120px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.login_form button span {
  color: #fff !important;
}
.login_form button:hover {
  background: #fff !important;
  border-color: #642226 !important;
  color: #642226 !important;
  transition: all 0.5s ease;
}
.login_form button:hover span {
  color: #642226 !important;
  transition: all 0.5s ease;
}
.login_form .ant-form-item-explain-error div {
  margin-top: 5px;
  margin-bottom: 10px;
}
.login_form label {
  font-size: 1.175rem !important;
  color: #181c32 !important;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
  float: left;
}
.logoutbtn {
  position: absolute !important;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  float: none;
  margin: 0 auto !important;
  margin-top: 15px;
  transition: all 0.5s ease;
  background: #fff !important;
  width: 120px;
  border: 1px solid #fff !important;
  border-color: #fff !important;
}
.logoutbtn span {
  color: #642226 !important;
  transition: all 0.5s ease;
}
.logoutbtn:hover {
  background: #fff !important;
  border-color: #642226 !important;
  color: #642226 !important;
  transition: all 0.5s ease;
}
.logoutbtn:hover span {
  color: #642226 !important;
  transition: all 0.5s ease;
}

.button_block {
  float: left !important;
}
.addbtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  float: right;
  margin-top: 15px;
  margin-right: 15px;
  transition: all 0.5s ease;
  background: transparent !important;
  /* width:120px;*/
  border: 1px solid #642226 !important;
  border-color: #642226 !important;
}
.addbtn span {
  color: #642226 !important;
  transition: all 0.5s ease;
  margin-right: 8px;
}
.addbtn:hover {
  background: #642226 !important;
  border-color: #642226 !important;
  color: #fff !important;
  transition: all 0.5s ease;
}
.addbtn:hover span {
  color: #fff !important;
  transition: all 0.5s ease;
}

.logo img {
  width: 50px;
  margin: 0 auto;
  display: flex;
  padding: 10px 0;
}
.ant-layout-header {
  background: transparent !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub,
.ant-layout-sider {
  background: #642226 !important;
}
.ant-layout-sider-trigger {
  background: #48181b !important;
  display: none;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #fff !important;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #642226 !important;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #642226 !important;
}
.ant-btn {
  border-radius: 0.42rem !important;
  height: 40px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.ant-btn > span {
}
.ant-checkbox-checked::after {
  border: 1px solid #642226 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #642226 !important;
  border-color: #642226 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #642226 !important;
}
.ant-layout {
  background-color: #fff;
}
.ant-breadcrumb {
  font-size: 16px !important;
}
.ant-spin-container {
  width: 100%;
  float: left;
}
.ant-menu-item {
  margin: 0 !important;
}
.ant-breadcrumb a:hover {
  color: #642226 !important;
}
.ant-breadcrumb > span {
  text-transform: capitalize;
}
.ant-anchor-wrapper {
  background-color: transparent !important;
}
.ant-table-thead > tr > th {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600 !important;
  width: 180px;
}
.ant-pagination-item-active {
  background: #642226 !important;
  border-color: #642226 !important;
}
.ant-pagination-item-active a {
  color: #fff !important;

  font-size: 0.925rem !important;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: #642226 !important;
  color: rgba(100, 34, 38, 0.25) !important;

  font-size: 0.925rem !important;
  line-height: 1.35 !important;
}
.ant-table-tbody > tr > td {
  text-transform: capitalize;
}
.ant-btn-primary {
  background: #fff !important;
  border-color: #642226 !important;
  border: 1px solid #642226 !important;
  color: #642226 !important;
}
.ant-btn-primary span {
  color: #642226 !important;
  margin-right: 0px;
}
.ant-btn {
  /*width:120px !important;*/
}
.ant-modal-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.ant-form-item-label {
  text-transform: capitalize;
}
.edit_form label {
  font-size: 16px !important;
  width: 100%;
  float: left;
  font-weight: 600;
}
.edit_form input,
.edit_form .ant-select-selector,
.edit_form textarea,
.edit_form .ant-picker {
  border-radius: 0.42rem !important;
  padding: 10px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-transform: capitalize;
}
.edit_form table {
  width: 100%;
}
.edit_form .ant-row {
  margin: 0 8px;
  margin-bottom: 20px;
}
.ant-btn > span {
  text-transform: capitalize;
}
.edit_button_block {
  float: right;
}
.edit_button_block button {
  float: left;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 20px !important;
}
.ant-modal-footer button:nth-child(1) {
  color: #642226 !important;
  background: #fff;
  border-color: #642226 !important;
}
.ant-modal-footer button:nth-child(2) {
  background-color: #642226 !important;
}
.ant-modal-footer button:nth-child(2) span {
  color: #fff !important;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #642226 !important;
  background: #fff;
  border-color: #642226 !important;
}
.main_top {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.main_top h2 {
  float: left;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-popover-inner,
.ant-popover-inner-content,
.ant-popover-buttons,
.ant-popover-buttons button {
  float: left;
}
.ant-space-item span svg {
  font-size: 20px;
}
.ant-popover-buttons button {
  width: 60px;
}

.ant-popover-buttons button:nth-child(1) {
  color: #642226 !important;
  background: #fff;
  border-color: #642226 !important;
}
.ant-popover-buttons button:nth-child(2) {
  background-color: #642226 !important;
}
.ant-popover-buttons button:nth-child(2) span {
  color: #fff !important;
}

.edit_button_block button:nth-child(1) {
  color: #642226 !important;
  background: #fff;
  border-color: #642226 !important;
}
.edit_button_block button:nth-child(1):hover {
  background: #642226 !important;
}
.edit_button_block button:nth-child(1):hover span {
  color: #fff !important;
}

.edit_button_block button:nth-child(2) {
  background-color: #642226 !important;
  border-color: #642226 !important;
}
.edit_button_block button:nth-child(2) span {
  color: #fff !important;
}

.edit_button_block button:nth-child(2):hover {
  background-color: #fff !important;
}
.edit_button_block button:nth-child(2):hover span {
  color: #642226 !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f !important;
}
.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}
/* .main_top
{
  display: flex;
    align-items: center;
    justify-content: space-between;
} */
.edit_form .ant-picker {
  width: 100%;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #642226 !important;
}

.ant-card-head-title,
.ant-card-body {
  font-size: 18px;
  font-weight: 600;
}

.ant-table-filter-column-title {
  width: 200px;
}

.ant-drawer-footer button:nth-child(1) {
  color: #642226 !important;
  background: #fff;
  border-color: #642226 !important;
}
.ant-drawer-footer button:nth-child(2) {
  background-color: #642226 !important;
}
.ant-drawer-footer button:nth-child(2) span {
  color: #fff !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 8px 8px 15px !important;
  border: 1px solid #f0f0f082 !important;
}
.ant-table-filter-column {
  margin: 0 !important;
}
.ant-table-filter-column-title {
  padding: 0 !important;
}

.incentive_block h1 {
  font-size: 18px;
  font-weight: bold;
  margin-right: 50px;
}
.incentive_block_main {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 45px rgb(183 178 178 / 9%);
}
.incentive_block_main h3 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}
.incentive_block_main h2 {
  margin-right: 15px;
}
.ant-input-number {
  width: 100% !important;
}
.incentive_block_main h2,
.incentive_block_main .ant-input-number {
  float: left;
  width: 90% !important;
}
.incentive_block button {
  float: right;
  background-color: #642226 !important;
  border: 1px solid #642226 !important;
  color: #fff !important;
}
.incentive_block button:hover {
  background-color: #fff !important;
  color: #642226 !important;
}
.incentive_button {
  -ms-flex: 0 0 54.16666667%;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
  float: left;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  /*  margin-left:0 !important;
  margin-right:8px;*/
}
.ant-btn > span + .anticon {
  margin-left: 0px !important;
}
.bottom_border {
  width: 60px;
  border-bottom: 2px solid #642226;
  margin-top: 5px;
  position: relative;
  top: 30px;
  left: 0;
}
.edit_form .ant-row .ant-col {
  width: 100%;
}
.ant-table {
  /*width: fit-content;*/
}

.ant-col-sm-16 {
  max-width: 100% !important;
}
.ant-modal-body {
  padding: 15px 5px 0px 5px !important;
  float: left;
  width: 100%;
  background-color: #fff;
}
.site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}
.daily_sheet .ant-btn-primary {
  color: #fff !important;
  background: #642226 !important;
  border-color: #642226 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.daily_sheet .ant-btn-primary span {
  color: #fff !important;
}

.daily_sheet .ant-btn-default {
  color: #642226 !important;
  background: #fff !important;
  border-color: #642226 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.daily_sheet .ant-btn-default span {
  color: #642226 !important;
}
.date_picker {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 20px;
}
.date_picker h2 {
  font-weight: 600;
  margin-bottom: 0;
}
.date_picker .ant-picker {
  margin: 0 auto;
  display: flex;
  width: 200px;
}
.daily_sheet {
  width: 100%;
  float: left;
}
.daily_button {
  width: auto;
  display: flex;
  margin: 0 auto 30px auto;
  justify-content: center;
}
.daily_sheet .ant-steps {
  width: max-content;
  margin: 0 auto;
  display: flex;
}
.daily_sheet .ant-steps-navigation .ant-steps-item {
  width: 200px !important;
  max-width: 200px;
}
.daily_sheet .daily_box {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.ant-drawer-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.ant-select-single .ant-select-selector {
  border-radius: 0.42rem !important;
  padding: 10px 20px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-transform: capitalize;
  border: 1px solid #642226 !important;
  color: #642226 !important;
  font-weight: 600;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #642226 !important;
  background-color: #642226 !important;
  color: #fff !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-arrow {
  color: #fff !important;
}
.ant-select-arrow {
  color: #642226 !important;
}
.ant-drawer-body {
  padding: 15px 5px 0px 5px !important;
}
.ant-input-number:hover {
  border-color: #642226 !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #642226 !important;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgb(100 34 38 / 20%) !important;
  box-shadow: 0 0 0 2px rgb(100 34 38 / 20%) !important;
}

.ant-input-number:hover {
  border-color: #642226 !important;
  border-right-width: 1px !important;
}

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #642226 !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(100 34 38 / 20%) !important;
}
.ant-picker {
  border-radius: 0.42rem !important;
  padding: 10px 20px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-transform: capitalize;
  border: 1px solid #642226 !important;
  color: #642226 !important;
  font-weight: 600;
}
.ant-form-item-label > label {
  font-size: 16px !important;
  width: 100%;
  float: left;
  font-weight: 600;
}
.inventory_table {
  border-collapse: collapse;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 30px;
  width: 100%;
  float: left;
}
.inventory_table tr th,
.inventory_table tr td {
  padding: 8px 8px 8px 15px;
  text-align: left;
  background-color: rgb(250, 250, 250);
  color: rgb(0, 0, 0);
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600 !important;
  width: 180px;
  border: 1px solid #f0f0f082 !important;
}
.denomination_button button {
  right: 30px;
  position: fixed;
  bottom: 30px;
}
.opening_block {
  width: 100%;
  float: left;
  padding: 20px;
  background-color: #f2f2f2;
  margin-bottom: 20px;
}
.opening_block p {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600 !important;
  margin-bottom: 0;
}
.opening_main .ant-drawer-body {
  padding: 20px !important;
}
.Tab_dailysheet {
  width: 100%;
  float: left;
  margin-top: 20px !important;
}
.Tab_dailysheet
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #642226 !important;
}

.Tab_dailysheet
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.Tab_dailysheet .ant-tabs-ink-bar {
  background: #642226 !important;
}
.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.absent_button {
  background-color: #ee4b2b !important;
  border: 1px solid #ee4b2b !important;
}
.absent_button:hover {
  border: 1px solid #ee4b2b !important;
}
.absent_button span {
  color: #fff !important;
}
.table_row_light {
  color: green;
}
.table_row_dark {
  color: red;
}
